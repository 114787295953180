import { type LineEstimate } from "./types";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export const retrieveEstimate = async (
  payload: any
): Promise<LineEstimate[]> => {

  // TODO - type the payload

  // await simulateApiError({path: "/cart/estimate", message: "PRICING ERROR"});

  const data = await fetchAPI2<LineEstimate[], any>("/cart/estimate", {
    method: "POST",
    body: payload,
  });

  return data;
};
